<template>
	<div>
		<table id="Proveedores" class="table-auto mt-4">
			<thead>
				<tr>
					<th class="border-2 px-4 py-2">Local</th>
					<th class="border-2 px-4 py-2">Rubro</th>
					<th class="border-2 px-4 py-2">Disponibilidad</th>
					<th class="border-2 px-4 py-2">Cuentas por pagar Bs</th>
					<th class="border-2 px-4 py-2">Cuentas por pagar $</th>
				</tr>
			</thead>
			<tbody>
				<tr v-for="(Proveedor, k) in localesTotal" :key="k">
					<td class="border-2 px-4 py-4">
						<router-link
							class="hover:text-blue-500 hover:underline"
							:to="{
								name: 'ProveedorCuenta',
								params: {
									id: Proveedor.id,
									nombre: Proveedor.nombre.replace(/\s/g, '_'),
									user: Proveedor.user_id
								}
							}"
							>{{ firstLetterUpperCase(Proveedor.nombre) }}</router-link
						>
					</td>
					<td class="border-2 px-4 py-4 text-justify">
						<ul class="list-disc ml-1">
							<li v-for="rubro in Proveedor.rubro" :key="rubro.id">
								{{ firstLetterUpperCase(rubro) }}
							</li>
							<br />
						</ul>
					</td>
					<td class="border-2 px-4 py-4">
						<div class="text-center text-green-600" v-if="Proveedor.show === 1">
							Disponible
						</div>
						<div class="text-center text-red-600" v-else>No disponible</div>
					</td>
					<td class="border-2 px-4 py-1 text-center">
						{{ formato(Proveedor.TotalBs) }}
					</td>
					<td class="border-2 px-4 py-4 text-center">
						{{ formato(Proveedor.TotalUsd) }}
					</td>
				</tr>
				<tr>
					<td></td>
					<td></td>
					<td class="border-2 px-4 py-4 text-center">Total</td>
					<td class="border-2 px-4 py-4 text-center">
						{{ formato(totalbs) }}
					</td>
					<td class="border-2 px-4 py-4 text-center">
						{{ formato(totalusd) }}
					</td>
				</tr>
			</tbody>
		</table>
	</div>
</template>

<script>
import { formato, firstLetterUpperCase } from "../functions.js";

export default {
	props: {
		totalusd: Number,
		totalbs: Number,
		localesTotal: Array
	},
	methods: {
		formato,
		firstLetterUpperCase
	}
};
</script>

<style></style>
