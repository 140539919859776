var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('table',{staticClass:"table-auto mt-4",attrs:{"id":"Proveedores"}},[_vm._m(0),_c('tbody',[_vm._l((_vm.localesTotal),function(Proveedor,k){return _c('tr',{key:k},[(Proveedor.show === _vm.filtro)?_c('td',{staticClass:"border-2 px-4 py-4"},[_c('router-link',{staticClass:"hover:text-blue-500 hover:underline",attrs:{"to":{
							name: 'ProveedorCuenta',
							params: {
								id: Proveedor.id,
								nombre: Proveedor.nombre.replace(/\s/g, '_'),
								user: Proveedor.user_id
							}
						}}},[_vm._v(_vm._s(_vm.firstLetterUpperCase(Proveedor.nombre)))])],1):_vm._e(),(Proveedor.show === _vm.filtro)?_c('td',{staticClass:"border-2 px-4 py-4 text-justify"},[_c('ul',{staticClass:"list-disc ml-1"},[_vm._l((Proveedor.rubro),function(rubro){return _c('li',{key:rubro.id},[_vm._v(" "+_vm._s(_vm.firstLetterUpperCase(rubro))+" ")])}),_c('br')],2)]):_vm._e(),(Proveedor.show === _vm.filtro)?_c('td',{staticClass:"border-2 px-4 py-4"},[(Proveedor.show === 1)?_c('div',{staticClass:"text-center text-green-600"},[_vm._v(" Disponible ")]):_c('div',{staticClass:"text-center text-red-600"},[_vm._v("No disponible")])]):_vm._e(),(Proveedor.show === _vm.filtro)?_c('td',{staticClass:"border-2 px-4 py-1 text-center"},[_vm._v(" "+_vm._s(_vm.formato(Proveedor.TotalBs))+" ")]):_vm._e(),(Proveedor.show === _vm.filtro)?_c('td',{staticClass:"border-2 px-4 py-4 text-center"},[_vm._v(" "+_vm._s(_vm.formato(Proveedor.TotalUsd))+" ")]):_vm._e()])}),_c('tr',[_c('td'),_c('td'),_c('td',{staticClass:"border-2 px-4 py-4 text-center"},[_vm._v("Total")]),_c('td',{staticClass:"border-2 px-4 py-4 text-center"},[_vm._v(" "+_vm._s(_vm.formato(_vm.totalbs))+" ")]),_c('td',{staticClass:"border-2 px-4 py-4 text-center"},[_vm._v(" "+_vm._s(_vm.formato(_vm.totalusd))+" ")])])],2)])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('thead',[_c('tr',[_c('th',{staticClass:"border-2 px-4 py-2"},[_vm._v("Local")]),_c('th',{staticClass:"border-2 px-4 py-2"},[_vm._v("Rubro")]),_c('th',{staticClass:"border-2 px-4 py-2"},[_vm._v("Disponibilidad")]),_c('th',{staticClass:"border-2 px-4 py-2"},[_vm._v("Cuentas por pagar Bs")]),_c('th',{staticClass:"border-2 px-4 py-2"},[_vm._v("Cuentas por pagar $")])])])}]

export { render, staticRenderFns }