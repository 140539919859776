<template>
	<div class="mt-20 md:mt-0">
		<div
			class="flex items-center justify-between mb-4 bg-verdedark w-full text-white font-bold fixed md:static"
		>
			<span class="ml-2">Resumen</span>
			<div>
				<span>Filtar por: </span>
				<select
					id="select"
					class="py-1 h-8 rounded-lg bg-verdedark border border-white text-white px-4 focus:outline-none"
					v-model="filtroSelect"
				>
					<option value="todos">Todos</option>
					<option value="1">Disponible</option>
					<option value="0">No Disponible</option>
				</select>
			</div>
			<div class="flex justify-center">
				<router-link to="/AgregarProveedorNuevo"
					><button
						class="font-bold py-2 px-4 inline-flex items-center focus:outline-none"
					>
						<svg
							id="Capa_1"
							enable-background="new 0 0 551.13 551.13"
							height="512"
							viewBox="0 0 551.13 551.13"
							width="512"
							class="h-6 w-6 mr-2 fill-current text-white"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="m275.565 0c-151.944 0-275.565 123.621-275.565 275.565s123.621 275.565 275.565 275.565 275.565-123.621 275.565-275.565-123.621-275.565-275.565-275.565zm0 516.685c-132.955 0-241.119-108.164-241.119-241.119s108.164-241.12 241.119-241.12 241.12 108.164 241.12 241.119-108.165 241.12-241.12 241.12z"
							/>
							<path
								d="m292.788 137.783h-34.446v120.56h-120.56v34.446h120.56v120.56h34.446v-120.56h120.56v-34.446h-120.56z"
							/>
						</svg>
						<span class="underline">Añadir Proveedor</span>
					</button></router-link
				>
			</div>
		</div>

		<div class="flex items-end justify-end mt-20 md:mt-4 mb-4 mr-8">
			<div class="flex items-center justify-center ml-2">
				<span>Total a pagar $</span>
				<div
					v-if="filtroSelect === 'todos'"
					class="ml-2 bg-secund border-secundB border rounded-md w-32 md:w-64 py-1 px-2 text-right"
				>
					{{ formato(totalusd) }}
				</div>
				<div v-else>
					<div
						v-if="filtroSelect === '1'"
						class="ml-2 bg-secund border-secundB border rounded-md w-32 md:w-64 py-1 px-2 text-right"
					>
						{{ formato(totalusdDisponibles) }}
					</div>
					<div
						v-else
						class="ml-2 bg-secund border-secundB border rounded-md w-32 md:w-64 py-1 px-2 text-right"
					>
						{{ formato(totalusdNoDisponibles) }}
					</div>
				</div>
			</div>
		</div>
		<!-- Muestra todos los proveedores segun su disponibilidad -->
		<div class="pb-10 flex justify-center" v-if="filtroSelect === 'todos'">
			<tabla-proveedores
				:localesTotal="localesTotal"
				:totalbs="totalbs"
				:totalusd="totalusd"
			></tabla-proveedores>
		</div>
		<!-- Muestra proveedores si esta disponible -->
		<div class="pb-10 flex justify-center" v-else>
			<div v-if="filtroSelect === '1'">
				<tabla-proveedores-filtro
					:localesTotal="localesTotal"
					:totalbs="totalbsDisponibles"
					:totalusd="totalusdDisponibles"
					:filtro="1"
				></tabla-proveedores-filtro>
			</div>
			<!-- Muestra proveedores si esta no disponible -->
			<div v-if="filtroSelect === '0'" class="flex justify-center">
				<tabla-proveedores-filtro
					:filtro="0"
					:localesTotal="localesTotal"
					:totalbs="totalbsNoDisponibles"
					:totalusd="totalusdNoDisponibles"
				></tabla-proveedores-filtro>
			</div>
		</div>
	</div>
</template>

<script>
import tablaProveedores from "../components/TablaProveedores.vue";
import tablaProveedoresFiltro from "@/components/TablaProveedoresFiltro.vue";
import { formato } from "../functions.js";

export default {
	name: "Proveedores",

	components: {
		tablaProveedores,
		tablaProveedoresFiltro
	},

	data: function() {
		return {
			totalbs: 0,
			totalusd: 0,
			totalbsDisponibles: 0,
			totalusdDisponibles: 0,
			totalbsNoDisponibles: 0,
			totalusdNoDisponibles: 0,
			localesTotal: [],
			message: "",
			showMessage: false,
			filtroSelect: "1"
		};
	},
	apollo: {
		localesTotal: {
			query: require("@/graphql/queries/totalLocalesCuentasPagar.gql"),
			fetchPolicy: "cache-and-network",
			update: data => data.localesTotal,
			error: (error, vm) => (vm.feedback.errorSearched = true),
			watchLoading(isLoading, countModifier) {
				// isLoading is a boolean
				// countModifier is either 1 or -1
				this.$store.state.isLoading = isLoading;
			}
		}
	},
	watch: {
		localesTotal: function totalesProveedores() {
			this.totalbs = 0;
			this.totalusd = 0;
			this.totalbsDisponibles = 0;
			this.totalusdDisponibles = 0;
			this.totalbsNoDisponibles = 0;
			this.totalusdNoDisponibles = 0;
			for (let i = 0; i < this.localesTotal.length; i++) {
				this.totalbs += this.localesTotal[i].TotalBs;
				this.totalusd += this.localesTotal[i].TotalUsd;
				if (this.localesTotal[i].show === 1) {
					this.totalbsDisponibles += this.localesTotal[i].TotalBs;
					this.totalusdDisponibles += this.localesTotal[i].TotalUsd;
				} else {
					this.totalbsNoDisponibles += this.localesTotal[i].TotalBs;
					this.totalusdNoDisponibles += this.localesTotal[i].TotalUsd;
				}
			}
		}
	},
	methods: {
		formato
	}
};
</script>
